export default function (doc) {
  if (doc.isBroken) {
    return '/404'
  }

  if (doc.type === 'homepage') {
    return '/'
  }

  if (doc.type === 'footer') {
    return '/'
  }

  if (doc.type === 'contact') {
    return '/'
  }

  // if (doc.type === 'about') {
  //   return '/about'
  // }

  return '/404'
}
