<template>
  <div>
    <Spinner v-if="!cmsContentPending" class="mt-5 mb-5" size="medium" line-fg-color="#1f9979" />
    <div v-else class="home">
      <!-- primary section -->
      <section class="primary">
        <b-container>
          <b-row>
            <b-col cols="12" md="6">
              <div v-html="$prismic.asHtml(this.primary_title)" class="primary__title"></div>
              <div v-html="$prismic.asHtml(this.primary_text)" class="main-text main-text--semi"></div>
              <a :href="this.primary_button_url" class="btn main-button main-button--wider">{{ $prismic.richTextAsPlain(this.primary_button_text) }}</a>
            </b-col>
            <b-col class="text-center" cols="12" md="6">
              <img v-lazy="this.primary_image.url" class="img-fluid primary__image" :alt="this.primary_image.alt" />
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- features section -->
      <section class="features">
        <b-container>
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <img v-lazy="this.features_icon_1.url" class="main-icon img-fluid" :alt="this.features_icon_1.alt" />
              <div v-html="$prismic.asHtml(this.features_title_1)" class="features__title"></div>
              <div v-html="$prismic.asHtml(this.features_text_1)" class="main-text text-left features__text"></div>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <img v-lazy="this.features_icon_2.url" class="main-icon img-fluid" :alt="this.features_icon_2.alt" />
              <div v-html="$prismic.asHtml(this.features_title_2)" class="features__title"></div>
              <div v-html="$prismic.asHtml(this.features_text_2)" class="main-text text-left features__text"></div>
            </b-col>
            <b-col cols="12" md="6" lg="4" offset-md="3" offset-lg="0">
              <img v-lazy="this.features_icon_3.url" class="main-icon img-fluid" :alt="this.features_icon_3.alt" />
              <div v-html="$prismic.asHtml(this.features_title_3)" class="features__title"></div>
              <div v-html="$prismic.asHtml(this.features_text_3)" class="main-text text-left features__text"></div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- logbook section -->
      <section class="logbook">
        <b-container>
          <b-row>
            <b-col cols="12" md="4">
              <img v-lazy="this.logbook_image_desktop.url" class="img-fluid d-none d-md-block" :alt="this.logbook_image_desktop.alt" />
              <img v-lazy="this.logbook_image_mobile.url" class="img-fluid d-md-none" :alt="this.logbook_image_mobile.alt" />
            </b-col>
            <b-col cols="12" md="8" class="logbook__box">
              <div v-html="$prismic.asHtml(this.logbook_title)" class="main-title logbook__title"></div>
              <hr class="main-line mt-4 mb-4" />
              <div v-html="$prismic.asHtml(this.logbook_text)" class="main-text"></div>
              <a :href="this.logbook_button_url" class="btn main-button main-button--wide">{{ $prismic.richTextAsPlain(this.logbook_button_text) }}</a>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- for homeowners section -->
      <section class="homeowners">
        <b-container class="homeowners__content">
          <b-row>
            <b-col cols="12" lg="6">
              <div v-html="$prismic.asHtml(this.homeowners_title)" class="main-title homeowners__title mb-4"></div>
              <div v-html="$prismic.asHtml(this.homeowners_text)" class="main-text"></div>
              <div class="homeowners__box">
                <a :href="this.homeowners_button_url" class="btn main-button main-button--wide main-button--small">{{ $prismic.richTextAsPlain(this.homeowners_button_text) }}</a>
              </div>
            </b-col>
            <b-col cols="12" lg="6">
              <div class="homeowners__iframe">
                <b-embed
                  type="iframe"
                  aspect="16by9"
                  :src="this.homeowners_video_url"
                  allowfullscreen
                ></b-embed>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- for business section -->
      <section class="features">
        <b-container>
          <div v-html="$prismic.asHtml(this.for_business_title)" class="main-title text-left mb-3"></div>
          <div v-html="$prismic.asHtml(this.for_business_text)" class="main-text text-left features__text"></div>
          <b-row>
            <b-col cols="12" md="6" lg="4">
              <img v-lazy="this.for_business_icon_1.url" class="main-icon img-fluid" :alt="this.for_business_icon_1.alt" />
              <h3 class="features__title"><a class="main-link" :href="this.for_business_feature_title_url_1">{{ $prismic.richTextAsPlain(this.for_business_feature_title_1) }}</a></h3>
              <p v-html="$prismic.asHtml(this.for_business_feature_text_1)" class="main-text text-left features__text"></p>
            </b-col>
            <b-col cols="12" md="6" lg="4">
              <img v-lazy="this.for_business_icon_2.url" class="main-icon img-fluid" :alt="this.for_business_icon_2.alt" />
              <h3 class="features__title"><a class="main-link" :href="this.for_business_feature_title_url_2">{{ $prismic.richTextAsPlain(this.for_business_feature_title_2) }}</a></h3>
              <p v-html="$prismic.asHtml(this.for_business_feature_text_2)" class="main-text text-left"></p>
            </b-col>
            <b-col cols="12" md="6" lg="4" offset-md="3" offset-lg="0">
              <img v-lazy="this.for_business_icon_3.url" class="main-icon img-fluid" :alt="this.for_business_icon_3.alt" />
              <h3 class="features__title"><a class="main-link" :href="this.for_business_feature_title_url_3">{{ $prismic.richTextAsPlain(this.for_business_feature_title_3) }}</a></h3>
              <p v-html="$prismic.asHtml(this.for_business_feature_text_3)" class="main-text text-left features__text"></p>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- testimonials section -->
      <section class="testimonials">
        <b-container>
          <div v-html="$prismic.asHtml(this.testimonials_title)" class="main-title testimonials__title text-left"></div>
          <carousel v-if="this.testimonial_section.length > 0" class="testimonials__carousel" :responsive="{0:{items:1},992:{items:2}}" :nav="false" :dots="false">
            <template slot="prev"><span class="prev"><img src="@/assets/images/home_page/chevron_left.svg" class="img-fluid testimonials__prev" alt="icon" /></span></template>
            <div v-for="(item, index) in this.testimonial_section" :key="`${index}`">
              <p class="main-text text-left testimonials__text">{{ $prismic.richTextAsPlain(item.testimonial_text) }}</p><div class="row testimonials__row"><div class="col-3 col-sm-2"><img :src="quote_icon.url" :alt="quote_icon.alt" class="img-fluid testimonials__icon" /></div><div class="col-9 col-sm-10"><p class="main-text"><b>{{ $prismic.richTextAsPlain(item.testimonial_person) }}</b><br> {{ $prismic.richTextAsPlain(item.testimonial_position) }}</p></div></div>
            </div>
            <template slot="next"><span class="next"><img src="@/assets/images/home_page/chevron_right.svg" class="img-fluid testimonials__next" alt="icon" /></span></template>
          </carousel>
        </b-container>
      </section>
      <!-- aside section -->
      <AsideComponent />
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel'
import Spinner from 'vue-simple-spinner'
export default {
  name: 'HomePage',
  components: {
    carousel,
    Spinner,
    AsideComponent: () => import('@/components/AsideComponent/AsideComponent.vue')
  },
  data: () => {
    return {
      cmsContentPending: false,
      primary_title: null,
      primary_text: null,
      primary_button_text: null,
      primary_button_url: null,
      primary_image: null,
      features_title_1: null,
      features_title_2: null,
      features_title_3: null,
      features_text_1: null,
      features_text_2: null,
      features_text_3: null,
      features_icon_1: null,
      features_icon_2: null,
      features_icon_3: null,
      logbook_title: null,
      logbook_text: null,
      logbook_button_text: null,
      logbook_image_desktop: null,
      logbook_image_mobile: null,
      homeowners_title: null,
      homeowners_text: null,
      homeowners_button_text: null,
      homeowners_video_url: null,
      for_business_title: null,
      for_business_text: null,
      for_business_icon_1: null,
      for_business_feature_title_1: null,
      for_business_feature_title_url_1: null,
      for_business_feature_text_1: null,
      for_business_icon_2: null,
      for_business_feature_title_2: null,
      for_business_feature_title_url_2: null,
      for_business_feature_text_2: null,
      for_business_icon_3: null,
      for_business_feature_title_3: null,
      for_business_feature_title_url_3: null,
      for_business_feature_text_3: null,
      testimonials_title: null,
      quote_icon: null,
      testimonial_section: null
    }
  },
  methods: {
    async getHomePageContent () {
      await this.$prismic.client.getSingle('homepage')
        .then((document) => {
          // primary section
          this.primary_title = document.data.primary_title
          this.primary_text = document.data.primary_text
          this.primary_button_text = document.data.primary_button_text
          this.primary_button_url = document.data.primary_button_text
          this.primary_image = document.data.primary_image_url
          // features section
          this.features_icon_1 = document.data.features_icon_1
          this.features_icon_2 = document.data.features_icon_2
          this.features_icon_3 = document.data.features_icon_3
          this.features_title_1 = document.data.features_title_1
          this.features_title_2 = document.data.features_title_2
          this.features_title_3 = document.data.features_title_3
          this.features_text_1 = document.data.features_text_1
          this.features_text_2 = document.data.features_text_2
          this.features_text_3 = document.data.features_text_3
          // logbook section
          this.logbook_title = document.data.logbook_title
          this.logbook_text = document.data.logbook_text
          this.logbook_button_text = document.data.logbook_button_text
          this.logbook_button_url = document.data.logbook_button_url.url
          this.logbook_image_desktop = document.data.logbook_image_desktop
          this.logbook_image_mobile = document.data.logbook_image_mobile
          // homeowners section
          this.homeowners_title = document.data.homeowners_title
          this.homeowners_text = document.data.homeowners_text
          this.homeowners_button_text = document.data.homeowners_button_text
          this.homeowners_button_url = document.data.homeowners_button_url.url
          this.homeowners_video_url = document.data.homeowners_video_url.url
          // for business section
          this.for_business_title = document.data.for_business_title
          this.for_business_text = document.data.for_business_text
          this.for_business_icon_1 = document.data.for_business_icon_1
          this.for_business_feature_title_1 = document.data.for_business_feature_title_1
          this.for_business_feature_title_url_1 = document.data.for_business_feature_title_url_1.url
          this.for_business_feature_text_1 = document.data.for_business_feature_text_1
          this.for_business_icon_2 = document.data.for_business_icon_2
          this.for_business_feature_title_2 = document.data.for_business_feature_title_2
          this.for_business_feature_title_url_2 = document.data.for_business_feature_title_url_2.url
          this.for_business_feature_text_2 = document.data.for_business_feature_text_2
          this.for_business_icon_3 = document.data.for_business_icon_3
          this.for_business_feature_title_3 = document.data.for_business_feature_title_3
          this.for_business_feature_title_url_3 = document.data.for_business_feature_title_url_3.url
          this.for_business_feature_text_3 = document.data.for_business_feature_text_3
          // testimonials section
          this.testimonials_title = document.data.testimonials_title
          this.quote_icon = document.data.quote_icon
          this.testimonial_section = document.data.testimonial_section
          // meta title
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          this.cmsContentPending = true
        })
    }
  },
  created () {
    this.getHomePageContent()
  }
}
</script>

<style lang="scss" scoped>
  @import "./HomePage.scss";
</style>
