import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage/HomePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/contact',
    name: 'ContactPage',
    component: () => import(/* webpackChunkName: "ContactPage" */ '../views/ContactPage/ContactPage.vue')
  },
  {
    path: '/404',
    name: '_404Page',
    component: () => import(/* webpackChunkName: "_404Page" */ '../views/_404Page/_404Page.vue')
  },
  {
    path: '*',
    redirect: '/404'
  },
  {
    path: '/blog',
    name: 'BlogPage',
    component: () => import(/* webpackChunkName: "BlogPage" */ '../views/BlogPage/BlogPage.vue')
  },
  {
    path: '/blog/:id',
    name: 'BlogPostPage',
    component: () => import(/* webpackChunkName: "BlogPostPage" */ '../views/BlogPostPage/BlogPostPage.vue')
  },
  {
    path: '/terms',
    name: 'TermsPage',
    component: () => import(/* webpackChunkName: "TermsPage" */ '../views/TermsPage/TermsPage.vue')
  },
  {
    path: '/privacy',
    name: 'PrivacyPage',
    component: () => import(/* webpackChunkName: "PrivacyPage" */ '../views/PrivacyPage/PrivacyPage.vue')
  },
  {
    path: '/chimnis_dei_policy',
    name: 'DeiPolicy',
    component: () => import(/* webpackChunkName: "DeiPolicy" */ '../views/DeiPolicy/DeiPolicy.vue')
  },
  {
    path: '/about',
    name: 'AboutPage',
    component: () => import(/* webpackChunkName: "AboutPage" */ '../views/AboutPage/AboutPage.vue')
  },
  {
    path: '/why-have-a-chimni-log',
    name: 'ChimniLogPage',
    component: () => import(/* webpackChunkName: "ChimniLogPage" */ '../views/ChimniLogPage/ChimniLogPage.vue')
  },
  {
    path: '/for-homeowners',
    name: 'ForHomeownersPage',
    component: () => import(/* webpackChunkName: "ForHomeownersPage" */ '../views/ForHomeownersPage/ForHomeownersPage.vue')
  },
  {
    path: '/for-business',
    name: 'ForBusinessPage',
    component: () => import(/* webpackChunkName: "ForBusinessPage" */ '../views/ForBusinessPage/ForBusinessPage.vue')
  },
  {
    path: '/estate-agents',
    name: 'EstateAgentsPage',
    component: () => import(/* webpackChunkName: "EstateAgentsPage" */ '../views/EstateAgentsPage/EstateAgentsPage.vue')
  },
  {
    path: '/building-house-histories-with-chimni',
    name: 'HouseHistoriesPage',
    component: () => import(/* webpackChunkName: "HouseHistoriesPage" */ '../views/HouseHistoriesPage/HouseHistoriesPage.vue')
  },
  {
    path: '/buying-and-selling-with-chimni',
    name: 'BuyingAndSellingPage',
    component: () => import(/* webpackChunkName: "BuyingAndSellingPage" */ '../views/BuyingAndSellingPage/BuyingAndSellingPage.vue')
  },
  {
    path: '/conveyancers',
    name: 'ConveyancersPage',
    component: () => import(/* webpackChunkName: "ConveyancersPage" */ '../views/ConveyancersPage/ConveyancersPage.vue')
  },
  {
    path: '/chimni-for-house-builders',
    name: 'HouseBuildersPage',
    component: () => import(/* webpackChunkName: "HouseBuildersPage" */ '../views/HouseBuildersPage/HouseBuildersPage.vue')
  },
  {
    path: '/running-projects-with-chimni',
    name: 'RunningProjectsPage',
    component: () => import(/* webpackChunkName: "RunningProjectsPage" */ '../views/RunningProjectsPage/RunningProjectsPage.vue')
  },
  {
    path: '/news',
    name: 'NewsroomPage',
    component: () => import(/* webpackChunkName: "NewsroomPage" */ '../views/NewsroomPage/NewsroomPage.vue')
  },
  {
    path: '/pdf-download',
    name: 'PdfDownloadPage',
    component: () => import(/* webpackChunkName: "NewsroomPage" */ '../views/PdfDownloadPage/PdfDownloadPage.vue')
  },
  {
    path: '/news/:id',
    name: 'NewsroomPostPage',
    component: () => import(/* webpackChunkName: "NewsroomPostPage" */ '../views/NewsroomPostPage/NewsroomPostPage.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes
})

export default router
