import axios from 'axios'

import { ERROR, PENDING, FEEDBACK } from '../mutationTypes'

// ENDPOINTS
const CONTACT_ENDPOINT = process.env.VUE_APP_CONTACT_ENDPOINT

const contact = {
  state: {
    error: null,
    pending: false,
    feedback: false
  },
  getters: {
    getError (state) {
      return state.error
    },
    getPending (state) {
      return state.pending
    },
    getFeedback  (state) {
      return state.feedback
    }
  },
  mutations: {
    [ERROR] (state, value) {
      state.error = value
    },
    [PENDING] (state, value) {
      state.pending = value
    },
    [FEEDBACK] (state, value) {
      state.feedback = value
    }
  },
  actions: {
    clearError (context) {
      context.commit(ERROR, null)
    },
    clearFeedback (context) {
      context.commit(FEEDBACK, null)
    },
    setPending (context, payload) {
      context.commit(PENDING, payload.pending)
    },
    async sendMessage (context, payload) {
      await axios({
        method: 'post',
        url: CONTACT_ENDPOINT,
        headers: {
          'Content-Type': 'application/json'
        },
        data: {
          contact_message: {
            name: payload.name,
            email: payload.email,
            body: payload.message
          }
        }
      })
        .then(response => {
          if (response.status === 201) {
            context.commit(FEEDBACK, true)
          } else {
            context.commit(ERROR, 'An error occurred, please try again later')
          }
        })
        .catch((error) => {
          context.commit(ERROR, error.response.data.errors.toString().replace(/,/g, ', '))
        })
    }
  }
}

export default contact
