<template>
  <div id="app">
    <vue-progress-bar />
    <HeaderComponent />
    <main>
      <router-view />
    </main>
    <FooterComponent />
    <CookiesComponent />
  </div>
</template>

<script>
export default {
  components: {
    HeaderComponent: () => import('@/components/HeaderComponent/HeaderComponent.vue'),
    FooterComponent: () => import('@/components/FooterComponent/FooterComponent.vue'),
    CookiesComponent: () => import('@/components/CookiesComponent/CookiesComponent.vue')
  },
  methods: {
    async getMetaContent () {
      await this.$prismic.client.getSingle('meta')
        .then((document) => {
          // meta section
          window.document.querySelector('head title').textContent = this.$prismic.richTextAsPlain(document.data.meta_title)
          window.document.querySelector('head meta[name="description"]').setAttribute('content', this.$prismic.richTextAsPlain(document.data.meta_description))
        })
    }
  },
  created () {
    //  progress bar
    this.$Progress.start()
    this.$router.beforeEach((to, from, next) => {
      if (to.meta.progress !== undefined) {
        const meta = to.meta.progress
        this.$Progress.parseMeta(meta)
      }
      this.$Progress.start()
      next()
    })
    this.$router.afterEach((to, from) => {
      this.$Progress.finish()
    })

    this.getMetaContent()
  },
  mounted () {
    this.$intercom.boot()
  }
}
</script>
