import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueCookie from 'vue-cookie'
import PrismicVue from '@prismicio/vue'
import linkResolver from './prismic/link-resolver'
import Vuelidate from 'vuelidate'
import VueProgressBar from 'vue-progressbar'
import VueLazyload from 'vue-lazyload'
import lineClamp from 'vue-line-clamp'
import VueGtag from 'vue-gtag'
import VueIntercom from 'vue-intercom'
import Embed from 'v-video-embed'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'pretty-checkbox/src/pretty-checkbox.scss'
import './styles/main.scss'

Vue.config.productionTip = false
Vue.config.devtools = false
Vue.config.debug = false
Vue.config.silent = true

Vue.use(Embed)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookie)
Vue.use(VueProgressBar, {
  color: '#1f9979',
  failedColor: 'red',
  thickness: '3px',
  transition: {
    speed: '.5s',
    opacity: '0.6s',
    termination: 1000
  }
})
Vue.use(lineClamp, {
  importCss: true
})
Vue.use(Vuelidate)
Vue.use(VueLazyload)
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_APP_ID })

Vue.use(VueGtag, {
  config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID }
})

const accessToken = process.env.VUE_APP_PRISMIC_ACCESS_TOKEN
const endpoint = process.env.VUE_APP_PRISMIC_ENDPOINT

// Register plugin
Vue.use(PrismicVue, {
  endpoint,
  apiOptions: { accessToken },
  linkResolver
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
